(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";
angular.module('app').component('esInsuranceIin', {
    templateUrl: 'components/es-insurance-with-iin/es-insurance-with-iin.html',
    controller: ['backend', '$q', InsuranceIinController],
    controllerAs: 'vm',
    bindings: {
        service: '=service',
        locked: '=locked',
        hasOtherServices: '='
    }
});

function InsuranceIinController(backend, $q) {

    var vm = this;

    vm.switchService = switchService;
    vm.switchServiceItem = switchServiceItem;
    vm.getInsuranceFullPrice = getInsuranceFullPrice;
    vm.updateAvailable = updateAvailable;
    vm.saveIinHandlers = [];

    vm.orderInfo = backend.getOrderInfo();

    updateAvailable();

    function updateAvailable() {

        vm.availableByPassengers = vm.orderInfo.passengers.map(function (passenger, passengerNum) {
            return (
                vm.service.itemsByPassengers[passengerNum].length &&
                passenger.nationalityCode === 'KZ' &&
                passenger.documentNumberDiscount &&
                (checkAdult(passenger) || hasAdultKzWithIin())
            );
        });

        vm.insuranceAvailable = hasAdultKz();
    }

    function hasAdultKzWithIin() {
        return vm.orderInfo.passengers.some(function (passenger) {
            return (
                checkAdult(passenger) &&
                passenger.documentNumberDiscount &&
                passenger.nationalityCode === 'KZ'
            );
        });
    }

    function hasAdultKz() {
        return vm.orderInfo.passengers.some(function (passenger) {
            return (
                checkAdult(passenger) &&
                passenger.nationalityCode === 'KZ'
            );
        });
    }

    function checkAdult(passenger) {
        var age = moment(backend.sessionParams.todayDate, 'DD.MM.YYYY').diff(
            moment(passenger.dateOfBirth, 'DD.MM.YYYY'),
            'years'
        );
        return age >= 18;
    }

    function switchService() {
        if (!vm.locked) {
            vm.service.active = !vm.service.active;
            if (vm.service.active) {
                if (vm.service.items.length === 1) {
                    backend.modifyExtraService(getInsuranceSubmitParams(vm.service.items[0]));
                }
            } else {
                backend.removeExtraService({
                    code: vm.service.onlineMode ? 'insuranceOnline' : 'insurance'
                });
            }
        }
    }

    function switchServiceItem(itemNum, passengerNum) {
        const params = getInsuranceSubmitParams(
            vm.service.itemsByPassengers[passengerNum][itemNum],
            vm.orderInfo.passengers[passengerNum].id
        );
        if (!vm.locked) {
            if (vm.service.itemsByPassengers[passengerNum][itemNum].selected) {
                backend.removeExtraService(params).then(function () {
                    vm.iinFormTouched[passengerNum] = false;
                });
            } else {
                if (vm.orderInfo.passengers[passengerNum].documentNumberDiscount) {
                    backend.modifyExtraService(params);
                } else {
                    if (checkAdult(vm.orderInfo.passengers[passengerNum]) || hasAdultKzWithIin()) {
                        vm.saveIinHandlers[passengerNum]().then(function () {
                            switchServiceItem(itemNum, passengerNum);
                        });
                    } else {
                        $q.race(vm.saveIinHandlers.filter(function (handler, num) {
                            return num !== passengerNum;
                        }).map(function (handler) {
                            return handler();
                        })).then(function () {
                            switchServiceItem(itemNum, passengerNum);
                        });
                    }

                }
            }
        }
    }

    function getInsuranceFullPrice(item) {
        return {
            value: item.price || Big(item.insurance_premium || 0).plus(item.luggage_premium || 0).toFixed(2),
            currency: item.currency
        };
    }

    function getInsuranceSubmitParams(item, passenger_id) {
        var params = {
            code: 'insurance',
            ins: item.ins,
            tins: item.tins,
            passenger_id: passenger_id
        };
        if (vm.service.onlineMode) {
            params.code = 'insuranceOnline';
            params.productCode = item.productCode;
        }
        return params;
    }

}

},{}],2:[function(require,module,exports){
"use strict";
angular.module('app').directive('iincheck', ['iinUtils', 'backend', function (iinUtils, backend) {
    return {
        require: 'ngModel',
        scope: {
            pax: '=iincheck'
        },
        link: function (scope, elm, attrs, ctrl) {
            if (!ctrl || !scope.pax) {
                return;
            }

            ctrl.$parsers.unshift(validate);
            ctrl.$formatters.unshift(validate);

            function validate(value) {
                var isValid = !value || iinUtils.isValidIinForPax(value, scope.pax.gender, scope.pax.dateOfBirth);
                ctrl.$setValidity('iincheck', isValid);
                if (isValid) {
                    delete ctrl.$error.customMessage;
                } else {
                    ctrl.$error.customMessage = backend.getAliasDynamic('web.extraServices.insurance.invalidIinMessage');
                }
                
                return value;
            }
        }
    };
}]);



},{}],3:[function(require,module,exports){
"use strict";
angular.module('app').component('passengerIinForm', {
    templateUrl: 'components/passenger-iin-form/passenger-iin-form.html',
    controller: ['backend', 'iinUtils', '$q', '$element', PassengerIinFormController],
    controllerAs: 'vm',
    bindings: {
        passenger: '=passenger',
        num: '=num',
        onModify: '&',
        submitTouched: '=touched',
        saveIin: '=?'
    }
});

function PassengerIinFormController(backend, iinUtils, $q, $element) {

    var vm = this;

    vm.saveIin = saveIin;
    vm.editableValue = getIinDefaultValue(vm.passenger);

    vm.$onInit = function () {
        iinUtils.addSaveIinHander(vm.saveIin, vm.num);
    };
    
    function saveIin() {

        vm.submitTouched = true;

       return $q(function (resolve, reject) {

            if (!vm.loading && vm.iinForm.$valid) {

                if (vm.editableValue != vm.passenger.documentNumberDiscount) {

                    vm.loading = true;

                    backend.modifyPassenger({
                        additionalDocumentType: 'ИИН',
                        additionalDocumentNumber: vm.editableValue,
                        index: vm.num
                    }).then(function () {
                        vm.loading = false;
                        vm.passenger.documentNumberDiscount = vm.editableValue;
                        vm.onModify();
                        resolve();
                    }, function () {
                        vm.loading = false;
                        reject();
                    });

                } else {
                    resolve();
                }

            } else {
                if (vm.iinForm.$invalid) {
                    jQuery('form.iinForm__js.ng-invalid', $element).triggerHandler('submit');
                }
                reject();
            }

        });
    }

    function getIinDefaultValue(passenger) {
        return passenger.documentNumberDiscount ? passenger.documentNumberDiscount : iinUtils.getIinStart(passenger.dateOfBirth, passenger.gender);
    }        

}
},{}],4:[function(require,module,exports){
"use strict";

require('./components/es-insurance-with-iin/es-insurance-with-iin');
require('./components/passenger-iin-form/passenger-iin-form');
require('./components/iincheck/iincheck');
require('./services/iinUtils');
require('./screens/booking/BookingScreenController');


},{"./components/es-insurance-with-iin/es-insurance-with-iin":1,"./components/iincheck/iincheck":2,"./components/passenger-iin-form/passenger-iin-form":3,"./screens/booking/BookingScreenController":5,"./services/iinUtils":6}],5:[function(require,module,exports){
"use strict";
angular.module('app').controller('BookingScreenController', 
    ['$scope', '$q', 'modalTools', 'backend', 'utils', 'iinUtils', 'redirect',
    function ($scope, $q, modalTools, backend, utils, iinUtils, redirect) {
            const vm = this;

            vm.loading = true;
            vm.orderServicesLoading = true;

            vm.submitPayment = submitPayment;
            vm.suffixCount = utils.suffixCount;
            vm.paymentVariantChangeHandler = paymentVariantChangeHandler;
            vm.swithcSubmitButtonHoverState = swithcSubmitButtonHoverState;

            $scope.$on('plasticCardForPaymentChangeEvent', function (event, data) {
                vm.card = data;
            });

            backend.ready.then(function() {
                angular.element('title').text(backend.getAliasWithPrefix('web.pageTitle.', 'extraServices'));

                backend.clearOrderInfoListeners();
                backend.clearUpdateOrderServicesListeners();

                backend.addOrderInfoListener(function(orderInfo) {
                    if (orderInfo &&
                        orderInfo.header &&
                        orderInfo.header.regnum &&
                        orderInfo.passengers &&
                        orderInfo.passengers[0] &&
                        orderInfo.passengers[0].lastName) {
                        vm.loading = true;

                        redirect.goToConfirmOrder();

                        return;
                    }

                    vm.orderInfo = orderInfo;
                });

                backend.addUpdateOrderServicesListener(function(resp) {
                    vm.orderInfo = resp[1];
                    vm.paymentVariants = resp[2];
                    vm.isFreePaymentVariants = utils.isFreePaymentVariants(resp[2]);

                    vm.es = utils.reformatAvailableExtraServices(resp[0], vm.orderInfo, vm.es);
                    vm.esList = utils.getAvailableExtraServicesList(resp[0], vm.es);
                    vm.loading = false;
                    vm.orderServicesLoading = false;

                    if (backend.getParam('ffp.enable') && (vm.orderInfo.hasBonusCard || vm.orderInfo.ffpSumm)) {
                        backend.ffpBonus().then(function(resp) {
                            vm.ffpBonus = resp.total || 0;
                        });
                    }

                }, function(resp) {
                    vm.loading = false;
                    vm.orderServicesLoading = false;
                    vm.errorMessage = resp.error;
                });

                backend.updateOrderServices(true).then(function() {
                    backend.switchDefaultSelectedServices(vm.esList, vm.es, vm.orderInfo);
                });

                backend.addExtraServiceListener(function(state) {
                    vm.modifyServicesLoading = !state;
                    vm.orderServicesLoading = true;
                });

                backend.addExtraServiceErrorListener(function(resp, req) {
                    if (!req || req.code !== 'seat') {
                        vm.modifyServicesError = resp.error;
                    }

                    vm.modifyServicesLoading = false;
                    vm.orderServicesLoading = true;
                });

            });

            function submitPayment() {
                if (vm.agree && !vm.modifyServicesLoading && !vm.orderServicesLoading) {
                    if (vm.paymentVariants[vm.selectedPaymentVariantIndex]) {
                        if (
                            vm.paymentVariants[vm.selectedPaymentVariantIndex].conflicts_with_aeroexpress ||
                            vm.paymentVariants[vm.selectedPaymentVariantIndex].conflicts_with_insurance
                        ) {
                            modalTools.openHandler('popup-cancel-conflict-services', false, {
                                paymentForm: vm.paymentVariants[vm.selectedPaymentVariantIndex].paymentForm,
                                aeroexpress: vm.paymentVariants[vm.selectedPaymentVariantIndex].conflicts_with_aeroexpress,
                                insurance: vm.paymentVariants[vm.selectedPaymentVariantIndex].conflicts_with_insurance,
                                submitCallback: submitPaymentHandler
                            });
                        } else {
                            $q.all(iinUtils.getSaveIinHanders().filter(function (handler, passengerNum) {
                                return (
                                    vm.orderInfo &&
                                    vm.orderInfo.groupedEditableExtraServices &&
                                    vm.orderInfo.groupedEditableExtraServices.insurance &&
                                    !!vm.orderInfo.groupedEditableExtraServices.insurance[passengerNum]
                                );
                            }).map(function (cb) {
                                return cb();
                            })).then(function () {
                                submitPaymentHandler();
                            });
                                
                        }
                    } else {
                        vm.showNeedSelectPaymentFormMesage = true;
                    }
                }
            }

            function submitPaymentHandler(removeInsuranceAeroexpress) {

                const selectedPaymentVariant = vm.paymentVariants[vm.selectedPaymentVariantIndex];

                vm.doBookingAndRegisterOrderLoading = true;

                if (vm.bookingError) {
                    delete vm.bookingError;
                }

                backend.doBookingAndRegisterOrder(
                    selectedPaymentVariant.paymentForm,
                    selectedPaymentVariant.paymentType,
                    removeInsuranceAeroexpress,
                    {
                        save_new_card: vm.saveNewCard,
                        card_token: selectedPaymentVariant.savedCard && selectedPaymentVariant.savedCard.token
                    }
                ).then(function (resp) {
                    if (resp.pnr && resp.lastName) {
                        redirect.goToConfirmOrder();
                    } else if (resp.eraseAeroexpressBecauseOfCurrency || resp.eraseInsuranceBecauseOfCurrency) {
                        modalTools.openHandler('popup-change-currency-error', false, {
                            eraseAeroexpressBecauseOfCurrency: resp.eraseAeroexpressBecauseOfCurrency,
                            eraseInsuranceBecauseOfCurrency: resp.eraseInsuranceBecauseOfCurrency,
                            mode: 'booking',
                            submitCallback: submitPaymentHandler
                        });
                    }

                    vm.doBookingAndRegisterOrderLoading = false;
                }, function(resp) {
                    vm.doBookingAndRegisterOrderLoading = false;
                    vm.bookingError = resp.error;
                });
            }

            function paymentVariantChangeHandler(newVariantIndex) {
                vm.showNeedSelectPaymentFormMesage = false;
                backend.changePaymentForm(vm.paymentVariants[newVariantIndex].paymentForm);
            }

            function swithcSubmitButtonHoverState() {
                vm.submitButtonHover = !vm.submitButtonHover;
            }

        }
    ]
);



},{}],6:[function(require,module,exports){
"use strict";
angular.module('app').factory('iinUtils', [
    function () {

        var saveIinHandlers = [];

        return {
            getIinStart: getIinStart,
            getIinCenturyGenderCode: getIinCenturyGenderCode,
            getCenturyByYear: getCenturyByYear,
            getIinCheckSumm: getIinCheckSumm,
            isValidIin: isValidIin,
            isValidIinForPax: isValidIinForPax,
            addSaveIinHander: addSaveIinHander,
            getSaveIinHanders: getSaveIinHanders
        };

        function getIinStart(dateOfBirth, gender) {
            var dateOfBirthMoment = moment(dateOfBirth, 'DD.MM.YYYY');
            return dateOfBirthMoment.format('YYMMDD') + getIinCenturyGenderCode(dateOfBirthMoment, gender);
        }
    
        function getIinCenturyGenderCode(dateOfBirthMoment, gender) {
            var century = getCenturyByYear(dateOfBirthMoment.year());
            if (gender === 'male') {
                if (century === 19) return '1';
                else if (century === 20) return '3';
                else if (century === 21) return '5';
            } else if (gender === 'female') {
                if (century === 19) return '2';
                else if (century === 20) return '4';
                else if (century === 21) return '6';
            }
            return '';
        }
    
        function getCenturyByYear(year) {
            return Math.floor(year/100) + 1;
        }

        function getIinCheckSumm(iinStr) {
            var a, b, summ;
            if (iinStr && iinStr.length === 11) {
                a = iinStr.split('');
                b = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11 ];
                summ = scal(a, b) % 11;
                if (summ === 10) {
                    b = [ 3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2 ];
                    summ = scal(a, b) % 11;
                }
                if (summ >= 0 && summ <= 9) {
                    return summ;
                }
            }
            return -1;
        }

        function isValidIin(iinStr) {
            if (iinStr && iinStr.length === 12) {
                if (
                    moment(iinStr.substr(0, 6), 'YYMMDD').isValid() &&
                    getIinCheckSumm(iinStr.substr(0, 11)) == iinStr[11]
                ) {
                    return true;
                }
            }
            return false;
        }

        function isValidIinForPax(iinStr, gender, dateOfBirth) {
            return (iinStr.substr(0, 7) === getIinStart(dateOfBirth, gender) && isValidIin(iinStr))
        }

        function scal(A, B) {
            var result = 0;
            if (A && B && A.length === B.length) {
                for (var i=0; i<A.length; i++) {
                    result += A[i] * B[i];
                }
                return result;
            }
        }

        function addSaveIinHander(cb, num) {
            saveIinHandlers[num] = cb;
        }

        function getSaveIinHanders() {
            return saveIinHandlers;
        }

    }
]);
},{}]},{},[4]);
